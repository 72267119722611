<template>
  <span>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          color="green"
          x-small
          @click="getAsyncDownloadFile()"
          :disabled="disabled"
          ><v-icon small>file_download</v-icon></v-btn
        >
      </template>
      <span>Baixar o arquivo {{ item? item.fileName: '' }}</span>
    </v-tooltip>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackBarMessage }}
    </v-snackbar>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FileDownload",
  
  props: { file: {
    required: true,
  } },

  data() {
    return {
      item: {},
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "",
      disabled: false,
    };
  },

  created() {
    this.item = this.file;
  },

  methods: {
    ...mapActions("file", ["ActionDownloadFile"]),
    async getAsyncDownloadFile() {
      if (this.item.id != undefined) {
        try {
          this.disabled = true;
          this.snackBarMessage = "Baixando o arquivo " + this.item.fileName;
          this.snackbar = true;
          const resul = await this.ActionDownloadFile(this.item.id);
          this.forceFileDownload(resul);
        } catch (err) {
          console.error(err);
          this.snackBarMessage = "Erro ao baixar o arquivo " + this.item.fileName;
          this.snackbar = true;
        }
        finally {
          this.disabled = false;
        }
      }
    },

    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const filename = response.headers.map["attachment-name"][0];
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
  },

  watch: {
    file(newFile) {
      this.item = newFile;
    },
  },
};
</script>

<style>
</style>